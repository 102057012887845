import { useApolloClient } from "@apollo/client";
import { Button, LegacyCard, Select, Text } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { apolloMutation, apolloQuery } from "@smartrr/shared/shopifyGraphQL";
import schema from "@smartrr/shared/shopifyGraphQL/schema.json";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import { graphql } from "cm6-graphql";
import { buildClientSchema } from "graphql";
import { gql } from "graphql-tag";
import React, { useCallback, useState } from "react";
import { Loader } from "rsuite";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

import { ResponseHeaderWrapper, ResponseText } from "./styles";
import CodeMirror, { EditorView } from "@uiw/react-codemirror";

const graphQLSchema = buildClientSchema(schema as any);

const CardWrapper = styled.div`
  box-shadow: inset 0px -1px 0px #e1e3e5;
`;

const ActionsWrapper = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
`;
const ButtonWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: flex-end;
`;

export function GraphQLView(): JSX.Element {
  const apolloClient = useApolloClient();
  const { addToast } = useToast();

  const [graphQLOperationType, setGraphQLOperationType] = useState<"query" | "mutation">("query");
  const [graphQLQuery, setGraphQLQuery] = useState<string>();
  const [apiResponse, setApiResponse] = useState<string>();
  const [loading, setLoading] = useState(false);

  const copyResponse = useCallback(async () => {
    if (apiResponse) {
      void copyToClipboard(apiResponse, "Response copied to clipboard", addToast);
    }
  }, [apiResponse, addToast]);

  const makeGraphQLRequest = useCallback(async () => {
    if (!graphQLQuery) {
      return;
    }

    try {
      const query = gql(graphQLQuery);

      setApiResponse("");
      setLoading(true);
      const res = await (graphQLOperationType === "query"
        ? apolloQuery(
            {
              query,
              fetchPolicy: "no-cache",
              context: {
                headers: {
                  "X-GraphQL-Cost-Include-Fields": true,
                },
              },
            },
            apolloClient
          )
        : apolloMutation(
            {
              mutation: query,
              context: {
                headers: {
                  "X-GraphQL-Cost-Include-Fields": true,
                },
              },
            },
            apolloClient
          ));
      if (res.type === "success") {
        setApiResponse(JSON.stringify(res.body, null, 2));
      } else {
        setApiResponse(res.message);
      }
    } catch (error) {
      addToast(`Error: ${error.message}`);
    }
    setLoading(false);
  }, [graphQLQuery, graphQLOperationType, addToast, apolloClient]);

  const OperationTypeOption = [
    { label: "Query", value: "query" },
    { label: "Mutation", value: "mutation" },
  ];

  const onChangeOperationType = useCallback(val => {
    setGraphQLOperationType(val);
  }, []);

  return (
    <React.Fragment>
      <CardWrapper>
        <LegacyCard.Section>
          <ActionsWrapper>
            <SelectWrapper>
              <Select
                label="Operation type"
                options={OperationTypeOption}
                onChange={onChangeOperationType}
                value={graphQLOperationType}
              />
            </SelectWrapper>
            <ButtonWrapper>
              <Button onClick={makeGraphQLRequest}>Send request</Button>
            </ButtonWrapper>
          </ActionsWrapper>
        </LegacyCard.Section>
      </CardWrapper>
      <CodeMirror
        value={graphQLQuery || ""}
        height={"300px"}
        extensions={[graphql(graphQLSchema), EditorView.lineWrapping]}
        onChange={setGraphQLQuery}
        onSubmit={makeGraphQLRequest}
      />
      <ResponseHeaderWrapper>
        <Text variant="bodyMd" as="span">
          API Response
        </Text>
        <Button onClick={copyResponse} icon={ClipboardMinor} />
      </ResponseHeaderWrapper>
      <ResponseText>
        {!!loading && <Loader />}
        {apiResponse}
      </ResponseText>
    </React.Fragment>
  );
}
