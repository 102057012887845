import { Modal, Text } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

import { CurrentPlanLabel } from "./CurrentPlanLabel";
import { planFeatures } from "../../constants";

const PlanInfoWrapper = styled.div`
  margin-bottom: 20px;

  :last-child {
    margin-bottom: 0px;
  }

  .Polaris-LegacyStack {
    margin-bottom: 10px;
  }

  span {
    display: block;
    text-align: justify;
    .Polaris-Text--subdued {
      display: inline;
    }
    .Polaris-Text--semibold {
      display: inline;
    }
  }
  .Polaris-Text--subdued {
    margin-bottom: 10px;
  }
`;

export function PlanFeaturesModal({ show, toggleModal }: { show: boolean; toggleModal(): void }): JSX.Element {
  return (
    <Modal open={show} onClose={toggleModal} title="Plan features">
      <Modal.Section>
        {planFeatures.map(plan => {
          const { id, planName, monthlyFee, subscriberFee, transactionFee, features } = plan;
          const isLegacyPlan = planName === "Legacy Pro" || planName === "Legacy Advanced";
          return (
            <PlanInfoWrapper key={id}>
              <CurrentPlanLabel plan={planName} />
              {!!isLegacyPlan && (
                <Text variant="bodyMd" as="span" color="subdued">
                  Only available to merchants with existing {planName} contracts
                </Text>
              )}
              <Text variant="bodyMd" as="p">
                Monthly fee:{" "}
                <Text variant="bodyMd" as="span" fontWeight="semibold">
                  ${monthlyFee}
                </Text>
              </Text>
              <Text variant="bodyMd" as="span">
                % Subscriber GMV fee:{" "}
                <Text variant="bodyMd" as="span" fontWeight="semibold">
                  {subscriberFee}%
                </Text>
              </Text>
              <Text variant="bodyMd" as="span">
                % Transaction fee:{" "}
                {transactionFee ? (
                  `$${transactionFee} per transaction`
                ) : (
                  <Text variant="bodyMd" as="span" color="subdued">
                    None
                  </Text>
                )}
              </Text>
              <Text variant="bodyMd" as="span">
                Features: {features}
              </Text>
            </PlanInfoWrapper>
          );
        })}
      </Modal.Section>
    </Modal>
  );
}
