import { LegacyCard, LegacyStack } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

interface ICurrentPlanLabel {
  plan: string;
}

const PlanLabelWrapper = styled.div`
  .Polaris-LegacyStack {
    .Polaris-LegacyStack__Item {
      .Polaris-LegacyCard {
        background: #1a1c1d;
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        padding: 9px 11px;
      }
    }
  }
`;

export function CurrentPlanLabel({ plan }: ICurrentPlanLabel): JSX.Element {
  const getPlans = () => {
    switch (plan) {
      case "Launch": {
        return "🚀 Launch";
      }
      case "Grow": {
        return "📈 Grow";
      }
      case "Excel": {
        return "💎 Excel";
      }
      case "Free Trial": {
        return "🆓 Free Trial";
      }
      case "Legacy Pro": {
        return "🪦 Legacy Pro";
      }
      case "Legacy Advanced": {
        return "🪦 Legacy Advanced";
      }
      default: {
        return "";
      }
    }
  };

  const planLabel = getPlans();

  if (!planLabel) {
    return <React.Fragment />;
  }

  return (
    <PlanLabelWrapper>
      <LegacyStack spacing="tight">
        <LegacyCard>{planLabel}</LegacyCard>
      </LegacyStack>
    </PlanLabelWrapper>
  );
}
