import { FormLayout, Modal, Text, TextField } from "@shopify/polaris";
import { adminRoutePrefix, shopUrlPropertyName } from "@smartrr/shared/constants";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { editPlanFees } from "@vendor-app/app/_state/actionCreators/superUser";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { ToggleSetting } from "./ToggleSetting";

const TransactionLabelWrapper = styled.div`
  align-items: end;
  display: flex;
  & > div {
    height: 25px;
  }
`;

export function EditPlanModal({ show, toggleModal }: { show: boolean; toggleModal(): void }): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();

  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const isLoadingFees = useSmartrrVendorSelector(state => state.accountPlans.isLoadingFees);

  const [reason, setReason] = useState("");
  const [transactionFee, setTransactionFee] = useState("");
  const [monthlyFee, setMonthlyFee] = useState("");
  const [subscriberFee, setSubscriberFee] = useState("");
  const [isDisabledTransactionFee, setIsDisabledTransactionFee] = useState(false);

  useEffect(() => {
    setTransactionFee(activePlan?.transactionFee.toString() ?? "");
    setMonthlyFee(activePlan?.planFee.toString() ?? "");
    setSubscriberFee(activePlan?.percentageFee.toString() ?? "");
  }, [activePlan]);

  const handleToggle = useCallback(
    () => setIsDisabledTransactionFee(isDisabledTransactionFee => !isDisabledTransactionFee),
    [isDisabledTransactionFee]
  );

  function getReturnUrl(hasActivePlan: boolean) {
    const returnUrl = new URL(window.location.href);
    if (!hasActivePlan) {
      returnUrl.pathname = adminRoutePrefix;
    }

    const cleanSearchParams = new URLSearchParams();
    const shop = returnUrl.searchParams.get(shopUrlPropertyName);
    if (shop) {
      cleanSearchParams.set(shopUrlPropertyName, shop);
    }
    returnUrl.search = cleanSearchParams.toString();

    return returnUrl.toString();
  }

  const onSave = () => {
    const body = {
      planFee: Number(monthlyFee),
      transactionFee: Number(transactionFee),
      percentageFee: Number(subscriberFee),
      returnUrl: getReturnUrl(!!activePlan),
    };
    dispatch(editPlanFees(body));
  };

  return (
    <Modal
      loading={isLoadingFees}
      open={show}
      onClose={toggleModal}
      title="Edit plan fees"
      primaryAction={{
        content: "Save",
        onAction: onSave,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: toggleModal,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              autoComplete="off"
              prefix="$"
              label="Monthly fee"
              value={monthlyFee}
              onChange={setMonthlyFee}
              type="number"
            />
            <TextField
              autoComplete="off"
              type="number"
              prefix="%"
              label="% Subscriber GMV fee"
              value={subscriberFee}
              onChange={setSubscriberFee}
            />
            <TextField
              autoComplete="off"
              type="number"
              prefix="%"
              label={
                <TransactionLabelWrapper>
                  <Text variant="bodyMd" as="p">
                    % Transaction fee
                  </Text>
                  <ToggleSetting active={isDisabledTransactionFee} onToggle={handleToggle} />
                </TransactionLabelWrapper>
              }
              value={transactionFee}
              onChange={setTransactionFee}
              disabled={!isDisabledTransactionFee}
            />
            <div />
            <TextField
              autoComplete="off"
              placeholder="Optional"
              label="Reason"
              value={reason}
              onChange={setReason}
              helpText="Will be added to internal client event log"
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
