import {
  Button,
  ContextualSaveBar,
  DatePicker,
  LegacyCard,
  LegacyStack,
  Modal,
  Page,
  Popover,
  Select,
  Text,
  TextField,
} from "@shopify/polaris";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { entries, keyBy, mapValues } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { switchToAccountPlan, syncAccountPlans } from "@vendor-app/app/_state/actionCreators/accountPlans";
import {
  cancelAppSubscription,
  cancelPendingPlan,
  editPlanFeatures,
  extendTrial,
  loadTrial,
  reactivateTrial,
} from "@vendor-app/app/_state/actionCreators/superUser";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { CurrentPlanLabel } from "./components/CurrentPlanLabel";
import { EditPlanModal } from "./components/EditPlanModal";
import { FeatureFlagItem } from "./components/FeatureFlagItem";
import { PlanFeaturesModal } from "./components/PlanFeaturesModal";
import { Spinner } from "../../components/elements/Spinner";
import { planOptions } from "../constants";
import { formatDateTime, getAccountFeatures } from "../utils";

import "./SuperUserEvent.css";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";

import { CopyClipboard } from "../../AdminSellingPlanGroupsRoute/components/SellingPlan";

import { ClipboardMinor } from "@shopify/polaris-icons";

import { getReturnUrl } from "../../AdminSmartrrAccount/components/AccountPlan";

import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";

const PageWrapper = styled.div`
  .Polaris-Page {
    padding: 0;
  }
`;

const ActionsWrapper = styled.div`
  background: #202123;
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.2),
    0px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  padding: 14px 24px;

  button:first-child {
    background: #202123;
    color: white;
    margin-right: 7px;
  }
`;

const DatePickerWrapper = styled.div`
  margin: 20px 16px;
  max-height: 284px;
  max-width: 290px;
`;

const FreeTrailWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  .Polaris-Text--headingMd {
    margin-bottom: 7px;
  }

  .Polaris-Text--subdued {
    span {
      font-weight: 500;
    }
  }
`;

const CurrentPlanWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;

  .Polaris-Text--subdued {
    margin-right: 5px;
  }
`;

const SwitchPlanWrapper = styled.div`
  margin-top: 10px;
`;

const PlanFeesWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;

  .Polaris-Button {
    text-decoration: underline;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlagsWrapper = styled.div`
  .Polaris-Text--headingMd {
    margin-bottom: 8px;
  }
`;

const FREE_TRIAL_PLAN_NAME = "Free Trial";

export function AdminActions(): JSX.Element {
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();

  const trialPlan = useSmartrrVendorSelector(state => state.superUser.accountPlan);
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const accountPlan = trialPlan ?? activePlan;
  const isFreeTrialPlan = accountPlan?.planName === FREE_TRIAL_PLAN_NAME;

  const trialPlanError = useSmartrrVendorSelector(state => state.superUser.errorMessage);
  const accountPlanError = useSmartrrVendorSelector(state => state.accountPlans.errorMessage);
  const errorMessage = trialPlanError || accountPlanError;
  const pendingPlan = useSmartrrVendorSelector(state => state.accountPlans.pendingPlan);

  const isLoading = useSmartrrVendorSelector(state => state.superUser.isLoading);

  let isPending = !!pendingPlan?.planName && !!pendingPlan.confirmationUrl && !isFreeTrialPlan;

  const [trialEndDate] = formatDateTime(accountPlan?.trialPeriodEnd);
  const calendarPopoverTitle = isFreeTrialPlan ? "Set new end date" : "Reactivate free trial";

  const allFeatures = Object.values(FeatureEnum).reduce(
    (acc, feature) => {
      acc[feature] = accountPlan?.features[feature] ?? false;
      return acc;
    },
    {} as Record<FeatureEnum, boolean>
  );

  const filteredAccountFeatures = accountPlan
    ? getAccountFeatures(allFeatures as Partial<Record<FeatureEnum, boolean>>)
    : [];

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState(accountPlan?.planName! ?? "");
  const [showEditPlanModal, setShowEditPlanModal] = useState(false);
  const [showPlanFeaturesModal, setShowPlanFeaturesModal] = useState(false);
  const [showCancelingModal, setShowCancelingModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const [mutatedFlags, setMutatedFlags] = useState<{ id: string; selected: boolean }[]>([]);

  const hasChanges = useMemo(() => {
    return (
      !!mutatedFlags.length ||
      (!isPending && (accountPlan?.planName || selectedPlan) && accountPlan?.planName !== selectedPlan)
    );
  }, [mutatedFlags.length, isPending, accountPlan?.planName, selectedPlan]);

  const onSave = useCallback(async () => {
    // Change of current plan
    if (activePlan?.planName !== selectedPlan) {
      const returnUrl = getReturnUrl(!!activePlan);
      await dispatch(switchToAccountPlan(selectedPlan, returnUrl, addToast, true));
    }

    // Change of feature flags
    if (mutatedFlags.length) {
      const features = entries(allFeatures);
      const mutableFeatures = features.map(([key, value]) => {
        const ele = mutatedFlags.find(flag => flag.id === key);
        return ele ? { value: ele.selected, key } : { value, key };
      });

      const newFeatures = mapValues(keyBy(mutableFeatures, "key"), "value");
      dispatch(editPlanFeatures(newFeatures, addToast)).then(res => {
        if (res.type === "EDITED_PLAN_FEATURES") {
          setMutatedFlags([]);
        }
      });
    }
  }, [activePlan, allFeatures, isFreeTrialPlan, mutatedFlags, selectedPlan]);

  const onDiscard = useCallback(() => {
    setSelectedPlan(activePlan?.planName!);
    setMutatedFlags([]);
  }, []);

  const handleSelectPlan = useCallback(value => setSelectedPlan(value), []);
  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);
  const handleToggle = useCallback(
    (id: string, selected: boolean) => {
      mutatedFlags.some(ele => ele.id === id)
        ? setMutatedFlags(() => mutatedFlags.filter(ele => ele.id !== id))
        : setMutatedFlags(prev => prev.concat({ id, selected }));
    },
    [mutatedFlags]
  );

  const toggleEditPlanModal = () => setShowEditPlanModal(!showEditPlanModal);
  const togglePlanFeaturesModal = () => setShowPlanFeaturesModal(!showPlanFeaturesModal);
  const toggleCancelingModal = () => setShowCancelingModal(show => !show);
  const toggleCalendar = () => {
    if (isPending) {
      addToast("Cannot add trial while plan is pending ");
      return;
    }
    if (isFreeTrialPlan) {
      setShowCalendar(!showCalendar);
    } else {
      dispatch(reactivateTrial()).then(() => {
        setSelectedPlan(FREE_TRIAL_PLAN_NAME);
      });
    }
  };

  const onConfirm = () => {
    // Get the difference between selected and trial dates
    const selectedDate = moment(selectedDates.end).startOf("day");
    const trialPeriodEndDate = moment(accountPlan?.trialPeriodEnd).startOf("day");
    const extendTrialTo = selectedDate.diff(trialPeriodEndDate, "days");

    dispatch(extendTrial(extendTrialTo));
    toggleCalendar();
    addToast("Free trial successfully extended");
  };
  const onCancel = () => {
    if (isPending) {
      dispatch(cancelPendingPlan(addToast)).then(res => {
        if (res.type === "CANCELED_PENDING_PLAN") {
          isPending = false;
        }
      });
    } else if (accountPlan) {
      dispatch(cancelAppSubscription());
      setSelectedPlan("");
    } else {
      addToast("No active account plan", true);
    }
    toggleCancelingModal();
  };

  useEffect(() => {
    dispatch(loadTrial());
    dispatch(syncAccountPlans());
  }, []);

  useEffect(() => {
    if (errorMessage) {
      addToast(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    const trialPeriodEnd = ISODateString.fromString(accountPlan?.trialPeriodEnd)
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toJSDate();

    setSelectedDates({ start: trialPeriodEnd, end: trialPeriodEnd });
  }, [accountPlan]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      {hasChanges ? (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{ onAction: onSave, loading: isLoading, content: "Save changes", disabled: false }}
          discardAction={{ content: "Discard changes", onAction: onDiscard }}
        />
      ) : null}
      <Page>
        <LegacyCard>
          <LegacyCard.Section>
            <FreeTrailWrapper>
              <div>
                <Text variant="headingMd" as="h2">
                  Extend free trial
                </Text>
                {accountPlan?.trialPeriodEnd ? (
                  <Text variant="bodyMd" as="span" color="subdued">
                    Currently ends:{" "}
                    <Text variant="bodyMd" as="span">
                      {trialEndDate}
                    </Text>
                  </Text>
                ) : (
                  <Text variant="bodyMd" as="span" color="subdued">
                    Client is not currently on a free trial
                  </Text>
                )}
              </div>
              <Popover
                active={showCalendar}
                activator={<Button onClick={toggleCalendar}>{calendarPopoverTitle}</Button>}
                autofocusTarget="first-node"
                onClose={toggleCalendar}
                preferredPosition="above"
                preferredAlignment="left"
              >
                <ActionsWrapper>
                  <Button onClick={toggleCalendar}>Cancel</Button>
                  <Button onClick={onConfirm} primary>
                    Confirm
                  </Button>
                </ActionsWrapper>
                <DatePickerWrapper>
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={setSelectedDates}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                  />
                </DatePickerWrapper>
              </Popover>
            </FreeTrailWrapper>
          </LegacyCard.Section>
        </LegacyCard>
        <LegacyCard>
          <LegacyCard.Section>
            <Text variant="headingMd" as="h2">
              Plan details
            </Text>
            <CurrentPlanWrapper>
              <LegacyStack alignment="center" spacing="extraTight">
                <Text variant="bodyMd" as="span" color={isPending ? "warning" : "subdued"}>
                  {isPending ? "Pending approval:" : accountPlan ? "Current plan:" : "No current plan"}
                </Text>
                <CurrentPlanLabel
                  plan={!isPending && accountPlan ? accountPlan?.planName! : pendingPlan?.planName!}
                />
              </LegacyStack>
              {accountPlan || isPending ? (
                <Button destructive plain onClick={toggleCancelingModal}>
                  Cancel plan
                </Button>
              ) : null}
            </CurrentPlanWrapper>
            <SwitchPlanWrapper>
              {isPending ? (
                <div style={{ marginBottom: "10px" }}>
                  <LegacyStack distribution="equalSpacing">
                    <Text variant="bodyMd" as="p">
                      Brand approval link
                    </Text>
                    <CopyClipboard>
                      <span className="copy-btn">
                        <Button
                          plain
                          icon={ClipboardMinor}
                          onClick={() =>
                            copyToClipboard(pendingPlan?.confirmationUrl ?? "", "Copied to clipboard", addToast)
                          }
                        >
                          Copy
                        </Button>
                      </span>
                    </CopyClipboard>
                  </LegacyStack>
                  <TextField
                    value={pendingPlan?.confirmationUrl}
                    label=""
                    autoComplete="false"
                    onChange={NO_OP_CALLBACK}
                    multiline={4}
                    disabled
                  />
                </div>
              ) : null}
              <Text variant="bodyMd" as="p">
                Switch plan
              </Text>
              <Select
                placeholder="Select one"
                label=""
                options={planOptions}
                onChange={handleSelectPlan}
                value={isPending ? pendingPlan?.planName : selectedPlan}
                disabled={isPending}
              />
              <Button plain onClick={togglePlanFeaturesModal}>
                Click to see a list of all plan features
              </Button>
            </SwitchPlanWrapper>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <PlanFeesWrapper>
              <OptionsWrapper>
                <Text variant="bodyMd" as="p">
                  Monthly fee:{" "}
                  <Text variant="bodyMd" as="span" fontWeight="semibold">
                    ${isPending ? pendingPlan?.planFee : accountPlan?.planFee}
                  </Text>
                </Text>
                <Text variant="bodyMd" as="p">
                  % Subscriber GMV fee: {isPending ? pendingPlan?.percentageFee : accountPlan?.percentageFee}%
                </Text>
                <Text variant="bodyMd" as="p">
                  % Transaction fee:{" "}
                  <Text variant="bodyMd" as="span" color="subdued">
                    {(isPending ? pendingPlan?.transactionFee : accountPlan?.transactionFee) || "None"}
                  </Text>
                </Text>
              </OptionsWrapper>
              <Button plain onClick={toggleEditPlanModal}>
                Edit plan fees
              </Button>
            </PlanFeesWrapper>
          </LegacyCard.Section>
        </LegacyCard>
        {accountPlan ? (
          <LegacyCard>
            <LegacyCard.Section>
              <FlagsWrapper>
                <Text variant="headingMd" as="h2">
                  Feature flags
                </Text>
                {filteredAccountFeatures.map(
                  flag =>
                    !!flag.label && (
                      <FeatureFlagItem
                        key={flag.id}
                        flag={flag}
                        handleToggle={handleToggle}
                        mutatedFlags={mutatedFlags}
                      />
                    )
                )}
              </FlagsWrapper>
            </LegacyCard.Section>
          </LegacyCard>
        ) : null}
        <EditPlanModal show={showEditPlanModal} toggleModal={toggleEditPlanModal} />
        <PlanFeaturesModal show={showPlanFeaturesModal} toggleModal={togglePlanFeaturesModal} />
        <Modal
          open={showCancelingModal}
          onClose={toggleCancelingModal}
          title="Cancel plan"
          titleHidden
          primaryAction={{
            content: "Yes",
            onAction: onCancel,
          }}
          secondaryActions={[
            {
              content: "No",
              onAction: toggleCancelingModal,
            },
          ]}
        >
          <Modal.Section>
            <Text variant="bodyLg" as="span">
              Are you sure you want to cancel this plan?
            </Text>
          </Modal.Section>
        </Modal>
      </Page>
    </PageWrapper>
  );
}
