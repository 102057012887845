import { Tabs, Page, Spinner } from "@shopify/polaris";
import UnreachableCaseError from "@vendor-app/utils/unreachableCaseError";
import React, { useMemo, useState } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { AdminActions } from "./AdminActions";
import { DebugScripts } from "./DebugScripts";
import { SyncActions } from "./SyncActions";
import { SetUpDmarc } from "./SetUpDmarc";

enum SuperUserSettingsTab {
  ADMIN_ACTIONS,
  SYNC_ACTIONS,
  DEBUG_SCRIPTS,
  DMARC,
}

export function AdminSuperUserRoute(): React.JSX.Element {
  const [selectedTab, setSelectedTab] = useState(SuperUserSettingsTab.ADMIN_ACTIONS);
  const authIsInitializing = useSmartrrVendorSelector(state => state.auth.isLoading);
  const {
    vendorOrganizations: { activeOrganizationId },
  } = useSmartrrVendorSelector(state => state);

  const tabs = useMemo(
    () => [
      { id: "admin-actions", content: "Admin actions" },
      { id: "sync-actions", content: "Sync actions" },
      { id: "debug-scripts", content: "Debug & scripts" },
      { id: "dmarc", content: "DMARC" },
    ],
    []
  );
  const tabsInner = useMemo(() => {
    if (authIsInitializing) {
      return <Spinner />;
    }
    return (() => {
      switch (selectedTab) {
        case SuperUserSettingsTab.ADMIN_ACTIONS: {
          return <AdminActions />;
        }
        case SuperUserSettingsTab.SYNC_ACTIONS: {
          return <SyncActions />;
        }
        case SuperUserSettingsTab.DEBUG_SCRIPTS: {
          return <DebugScripts organizationId={activeOrganizationId} />;
        }
        case SuperUserSettingsTab.DMARC: {
          return <SetUpDmarc />;
        }
        default: {
          throw new UnreachableCaseError(selectedTab);
        }
      }
    })();
  }, [selectedTab, authIsInitializing]);

  return (
    <React.Fragment>
      <Page narrowWidth>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} fitted>
          {tabsInner}
        </Tabs>
      </Page>
    </React.Fragment>
  );
}
