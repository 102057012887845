import { Navigate, createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { AdminSuperUserRoute } from "@vendor-app/app/AdminRoute/AdminSuperUserRoute";

export const Route = createLazyFileRoute("/admin/super-user")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  const isSuperUser = useIsSuperUserSelector();

  return isSuperUser ? <AdminSuperUserRoute /> : <Navigate to="/admin/home" />;
}
