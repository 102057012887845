import { Button } from "@shopify/polaris";
import { captureException } from "@smartrr/shared/utils/captureException";
import React, { useCallback, useEffect, useState } from "react";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

interface IWebhookButtonProps {
  onWebhooksStateChange: (activated: boolean) => void;
}

export function WebhookButton({ onWebhooksStateChange }: IWebhookButtonProps): React.JSX.Element {
  const [loading, setLoading] = useState(false);
  const [webhooksActive, setWebhooksActive] = useState<boolean | null>(null);

  // Toggles the webhook state and handles the API call response
  const toggleWebhookState = useCallback(async () => {
    setLoading(true);
    let response;

    try {
      if (webhooksActive) {
        response = await typedFrontendVendorApi.deleteReq("/webhooks-listening");
      } else {
        response = await typedFrontendVendorApi.postReq("/webhooks-listening");
      }

      // If response is successful, toggle webhooks state
      if (response.type === "success") {
        const nextState = !webhooksActive;
        onWebhooksStateChange?.(nextState);
        setWebhooksActive(nextState);
      }
    } catch (error) {
      captureException("Error toggling webhooks state", error);
    } finally {
      setLoading(false);
    }
  }, [onWebhooksStateChange, webhooksActive]);

  // Fetches the webhook state when component mounts
  useEffect(() => {
    const fetchWebhookState = async () => {
      setLoading(true);
      try {
        const response = await typedFrontendVendorApi.getReq("/webhooks-listening");

        // If response is successful, update webhooks state
        if (response.type === "success") {
          setWebhooksActive(response.body.isFullySubscribed);
        }
      } catch (error) {
        captureException("Error fetching webhooks state", error);
      } finally {
        setLoading(false);
      }
    };

    void fetchWebhookState();
  }, []);

  // Display loading button when webhook state is not known
  if (webhooksActive === null) {
    return <Button disabled>Loading...</Button>;
  }

  return (
    <Button onClick={toggleWebhookState} disabled={loading}>
      {webhooksActive ? "Deactivate" : "Activate"} Webhooks
    </Button>
  );
}
