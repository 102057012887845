import React from "react";

export const OffIcon = () => {
  return (
    <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="3" width="26" height="10" rx="5" fill="#E4E5E7" />
      <circle cx="8" cy="8" r="8" fill="#BABEC3" />
    </svg>
  );
};
