import { SettingToggle } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

import { OffIcon, OnIcon } from "@vendor-app/app/_sharedComponents/Icons";

interface IFeatureFlagItem {
  active: boolean;
  onToggle: () => void;
}

const ToggleWrapper = styled.div`
  .Polaris-LegacyCard {
    box-shadow: none;
    .Polaris-LegacyCard__Section {
      padding: 0;
      .Polaris-SettingAction {
        background: transparent;
        .Polaris-SettingAction__Setting {
          margin: 0;
        }
        ,
        .Polaris-SettingAction__Action {
          margin: 0;
        }
        ,
        .Polaris-Button {
          background: transparent;
          border: none;
          box-shadow: none;
          color: #8c9196;
        }
        ,
        .Polaris-Button__Content {
          .Polaris-Button__Icon {
            padding-right: 10px;
          }
        }
      }
    }
  }
`;

export function ToggleSetting({ active, onToggle }: IFeatureFlagItem): JSX.Element {
  return (
    <ToggleWrapper>
      <SettingToggle
        action={{
          onAction: onToggle,
          icon: active ? OnIcon : OffIcon,
        }}
        enabled={active}
      />
    </ToggleWrapper>
  );
}
