import { Button, Checkbox, LegacyCard, Select, Text } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { ResponseHeaderWrapper, ResponseText } from "./styles";
import { UploadFile } from "./UploadFile";

interface IScriptsView {
  isRunScript: boolean;
  setIsRunScript: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScriptTypeWrapper = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: flex-end;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export function ScriptsView({ isRunScript, setIsRunScript }: IScriptsView): JSX.Element {
  const [scriptType, setScriptType] = useState("");
  const [failedChecked, setFailedChecked] = useState(false);
  const [cancelledChecked, setCancelledChecked] = useState(false);
  const handleFailedChange = useCallback(checked => setFailedChecked(checked), []);
  const handleCancelledChange = useCallback(checked => setCancelledChecked(checked), []);
  const ScriptsTypeOptions = [{ label: "Contract update", value: "contract-update" }];
  const isProgressComplete = false;

  const onChangeScriptType = useCallback(val => {
    setScriptType(val);
  }, []);

  return (
    <React.Fragment>
      <LegacyCard.Section>
        <ScriptTypeWrapper>
          <SelectWrapper>
            <Select
              label="Script type"
              options={ScriptsTypeOptions}
              onChange={onChangeScriptType}
              value={scriptType}
            />
          </SelectWrapper>
          <ButtonWrapper>
            <Button plain>Download CSV template</Button>
          </ButtonWrapper>
        </ScriptTypeWrapper>
        <CheckboxWrapper>
          <Checkbox label="Set to failed" checked={failedChecked} onChange={handleFailedChange} />
          <Checkbox label="Set to cancelled" checked={cancelledChecked} onChange={handleCancelledChange} />
        </CheckboxWrapper>
      </LegacyCard.Section>
      <UploadFile isRunScript={isRunScript} setIsRunScript={setIsRunScript} />
      {!!isProgressComplete && (
        <React.Fragment>
          <ResponseHeaderWrapper>
            <Text variant="bodyMd" as="span">
              Script status:
            </Text>
            <Button icon={ClipboardMinor} />
          </ResponseHeaderWrapper>
          <ResponseText>
            Failed to parse file at row [516731]: Json mapping exception: unexpected character (&apos;x&apos;
            (code 120)): Expected separator (&apos;&quot;&apos; (code 34)) or end-of-line at [Source:
            java.io.BufferedReader@437c6e26; line: 516747, column: 423] at [Source:
            java.io.BufferedReader@437c6e26; line: 516747, column: 398] (through reference chain: Object[][15])
          </ResponseText>{" "}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
