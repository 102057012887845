import { json } from "@codemirror/lang-json";
import { Button, LegacyCard, Select, Text } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";
import React, { useCallback, useState } from "react";
import { Loader } from "rsuite";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useActiveOrganizationIdSelector } from "@vendor-app/app/_state/reducers/organizations";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { ResponseHeaderWrapper, ResponseText } from "./styles";
import CodeMirror, { EditorView } from "@uiw/react-codemirror";

interface Props {
  apiMap: { [key: string]: string[] };
}

const CardWrapper = styled.div`
  .Polaris-LegacyCard__Section {
    padding-bottom: 10px;
  }
  box-shadow: inset 0px -1px 0px #e1e3e5;
`;

const SelectsWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  :first-child {
    margin-right: 22px;
  }
`;

export function RestView({ apiMap }: Props): JSX.Element {
  const organizationId = useActiveOrganizationIdSelector();
  const { addToast } = useToast();

  const [selectedApi, setSelectedApi] = useState<string>("");
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [params, setParams] = useState<string>();
  const [apiResponse, setApiResponse] = useState<string>();
  const [loading, setLoading] = useState(false);

  const sendApiRequest = useCallback(async () => {
    if (!organizationId || !selectedAction || !selectedApi) {
      return;
    }

    let formattedParams;
    try {
      formattedParams = params ? JSON.parse(params) : [];
    } catch {
      console.error("invalid param format");
    }

    setApiResponse("");
    setLoading(true);
    const res = await typedFrontendVendorApi.postReq("/shopify-api", {
      reqBody: {
        action: selectedAction,
        api: selectedApi,
        params: formattedParams,
      },
    });

    setApiResponse(JSON.stringify(res, null, 2));
    setLoading(false);
  }, [organizationId, selectedAction, selectedApi, params]);

  const onApiSelect = useCallback(
    val => {
      setSelectedApi(val);
      setSelectedAction("");
    },
    [apiMap]
  );

  const onActionSelect = useCallback(
    val => {
      setSelectedAction(val);
    },
    [apiMap]
  );

  const copyResponse = useCallback(async () => {
    if (apiResponse) {
      copyToClipboard(apiResponse, "Response copied to clipboard", addToast);
    }
  }, [apiResponse]);

  return (
    <React.Fragment>
      <CardWrapper>
        <LegacyCard.Section>
          <SelectsWrapper>
            <SelectWrapper>
              <Select
                label="Select API"
                placeholder="Select one"
                options={Object.keys(apiMap).map(api => {
                  return {
                    label: api,
                    value: api,
                  };
                })}
                onChange={onApiSelect}
                value={selectedApi}
              />
            </SelectWrapper>
            <SelectWrapper>
              <Select
                label="Select action"
                placeholder="Select one"
                options={
                  selectedApi
                    ? apiMap[selectedApi].map(action => {
                        return {
                          label: action,
                          value: action,
                        };
                      })
                    : undefined
                }
                onChange={onActionSelect}
                value={selectedAction}
              />
            </SelectWrapper>
          </SelectsWrapper>
          <Text variant="bodyMd" as="p">
            Params: ([param1, param2, ...])
          </Text>
        </LegacyCard.Section>
      </CardWrapper>
      <CodeMirror
        value={params || ""}
        height={"300px"}
        extensions={[json(), EditorView.lineWrapping]}
        onChange={setParams}
        onSubmit={sendApiRequest}
      />
      <ResponseHeaderWrapper>
        <Text variant="bodyMd" as="span">
          API Response
        </Text>
        <Button onClick={copyResponse} icon={ClipboardMinor} />
      </ResponseHeaderWrapper>
      <ResponseText>
        {!!loading && <Loader />}
        {apiResponse}
      </ResponseText>
    </React.Fragment>
  );
}
