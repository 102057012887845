import { SmartRRWebSocket } from "@vendor-app/app/SmartRRWebSocket";
import { create } from "zustand";

interface SellingPlanSyncStore {
  isSyncing: boolean;
  completed: number;
  total: number;
  actions: {
    syncSellingPlans: (organizationId: string) => Promise<void>;
  };
}

export const useSellingPlanSyncStore = create<SellingPlanSyncStore>(set => ({
  isSyncing: false,
  completed: 0,
  total: 100,
  actions: {
    async syncSellingPlans(organizationId: string) {
      set({ isSyncing: true, completed: 0, total: 100 });
      const ws = await SmartRRWebSocket.create(organizationId);
      ws.createTopicListener("/selling-plan-groups/actions/pull", message => {
        set({
          completed: message.completed,
          total: message.total,
          isSyncing: !message.done,
        });
      }).send();
    },
  },
}));
