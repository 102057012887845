import { Stack } from "@mui/material";
import { Badge, Button, DataTable, Card, Page, Spinner, Text, Box } from "@shopify/polaris";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import React, { useEffect } from "react";

import styled from "styled-components";
import { DmarcStoreAccess } from "./store";

const PageWrapper = styled.div`
  .Polaris-Page {
    padding: 0;
  }
`;

const FooterButton = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 5px;
`;

export function SetUpDmarc(): React.JSX.Element {
  const { addToast } = useToast();
  const isLoading = DmarcStoreAccess.useIsLoading();
  const { loadDmarc, setUp, verify } = DmarcStoreAccess.useActions();
  const isVerifying = DmarcStoreAccess.useIsVerifying();
  const isSettingUp = DmarcStoreAccess.useIsSettingUp();
  const dmarcData = DmarcStoreAccess.useDmarcData();

  const verifyRecords = async () => {
    const response = await verify();
    if (response.result === "success") {
      response.verified
        ? addToast("DMARC authentication verified")
        : addToast("DMARC authentication is not verified yet", true);
    } else {
      addToast("Error verifying DMARC authentication", true);
    }
  };

  const handleSetUp = async () => {
    const response = await setUp();
    if (response.result === "success") {
      addToast("DMARC authentication successful");
    } else {
      addToast("Error setting up DMARC authentication", true);
    }
  };

  useEffect(() => {
    const fetchDmarc = async () => {
      await loadDmarc();
    };

    void fetchDmarc();
  }, [isLoading]);

  return (
    <PageWrapper>
      <Page>
        {isLoading ? (
          <Card>
            <div style={{ height: "119px" }}>
              <Stack alignItems="center" justifyContent="center" height="100%">
                <Spinner />
              </Stack>
            </div>
          </Card>
        ) : (
          <React.Fragment>
            {dmarcData ? (
              <Card>
                <Text as="h2" variant="headingLg">
                  DNS Record
                </Text>
                <Box paddingBlockStart="3">
                  <Text as="p">Merchant needs to configure the following DNS record:</Text>

                  <DataTable
                    columnContentTypes={["text", "text", "text", "text"]}
                    headings={["Status", "Hostname", "Type", "Value"]}
                    rows={[
                      [
                        <Badge key={"dns-record"} status={dmarcData.verified ? "success" : "warning"}>
                          {dmarcData.verified ? "Verified" : "Unverified"}
                        </Badge>,
                        dmarcData.mailfrom,
                        "CNAME",
                        dmarcData.authenticatedEmailDomain,
                      ],
                    ]}
                  />
                  <FooterButton>
                    <Button disabled={dmarcData.verified} loading={isVerifying} onClick={verifyRecords}>
                      Verify
                    </Button>
                  </FooterButton>
                </Box>
              </Card>
            ) : (
              <Card>
                <Text as="h2" variant="headingLg">
                  Set up DMARC
                </Text>

                <Box paddingBlockStart="3">
                  <Text as="p">Merchant does not have DMARC setup yet.</Text>

                  <FooterButton>
                    <Button loading={isSettingUp} onClick={handleSetUp}>
                      Set up
                    </Button>
                  </FooterButton>
                </Box>
              </Card>
            )}
          </React.Fragment>
        )}
      </Page>
    </PageWrapper>
  );
}
