import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

interface IEditPlanFeesBody {
  planFee: number;
  transactionFee: number;
  percentageFee: number;
  returnUrl: string;
}

export const loadTrial =
  (): SmartrrThunkAction<"LOADING_TRIAL" | "ERROR_LOADING_TRIAL" | "LOADED_TRIAL"> => dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/super/trial"),
      () =>
        dispatch({
          type: "LOADING_TRIAL",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_TRIAL",
          payload: {
            errorMessage: failure.message,
          },
        }),
      payload =>
        dispatch({
          type: "LOADED_TRIAL",
          payload: {
            accountPlan: payload?.accountPlan ?? null,
            remainingTrialDays: payload?.remainingTrialDays ?? 0,
          },
        })
    );

export const reactivateTrial =
  (): SmartrrThunkAction<"REACTIVATING_TRIAL" | "ERROR_REACTIVATING_TRIAL" | "REACTIVATED_TRIAL"> => dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/super/reactivate-trial"),
      () =>
        dispatch({
          type: "REACTIVATING_TRIAL",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_REACTIVATING_TRIAL",
          payload: {
            errorMessage: failure.message,
          },
        }),
      accountPlan =>
        dispatch({
          type: "REACTIVATED_TRIAL",
          payload: {
            accountPlan,
          },
        })
    );

export const extendTrial =
  (extendTrialTo: number): SmartrrThunkAction<"EXTENDING_TRIAL" | "ERROR_EXTENDING_TRIAL" | "EXTENDED_TRIAL"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/super/extend-trial", {
        reqBody: {
          extendTrialTo,
        },
      }),
      () =>
        dispatch({
          type: "EXTENDING_TRIAL",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_EXTENDING_TRIAL",
          payload: {
            errorMessage: failure.message,
          },
        }),
      accountPlan =>
        dispatch({
          type: "EXTENDED_TRIAL",
          payload: {
            accountPlan,
          },
        })
    );

export const cancelAppSubscription =
  (): SmartrrThunkAction<
    "CANCELING_APP_SUBSCRIPTION" | "ERROR_CANCELING_APP_SUBSCRIPTION" | "CANCELED_APP_SUBSCRIPTION"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/super/cancel-active-plan"),
      () =>
        dispatch({
          type: "CANCELING_APP_SUBSCRIPTION",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_CANCELING_APP_SUBSCRIPTION",
          payload: {
            errorMessage: failure.message,
          },
        }),
      appSubscription =>
        dispatch({
          type: "CANCELED_APP_SUBSCRIPTION",
          payload: {
            appSubscription,
          },
        })
    );

export const editPlanFeatures =
  (
    features: any,
    addToast: (content: string, error?: boolean | undefined) => void
  ): SmartrrThunkAction<"EDITING_PLAN_FEATURES" | "ERROR_EDITING_PLAN_FEATURES" | "EDITED_PLAN_FEATURES"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/super/edit-plan-features", {
        reqBody: features,
      }),
      () =>
        dispatch({
          type: "EDITING_PLAN_FEATURES",
          payload: undefined,
        }),
      failure => {
        addToast(`Error updating feature flags: ${failure.message}`, true);
        return dispatch({
          type: "ERROR_EDITING_PLAN_FEATURES",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      accountPlan => {
        addToast("Feature flags updated");
        return dispatch({
          type: "EDITED_PLAN_FEATURES",
          payload: {
            accountPlan,
          },
        });
      }
    );

export const editPlanFees =
  (
    body: IEditPlanFeesBody
  ): SmartrrThunkAction<"EDITING_PLAN_FEES" | "ERROR_EDITING_PLAN_FEES" | "EDITED_PLAN_FEES"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/super/edit-plan-fees", {
        reqBody: body,
      }),
      () =>
        dispatch({
          type: "EDITING_PLAN_FEES",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_EDITING_PLAN_FEES",
          payload: {
            errorMessage: failure.message,
          },
        }),
      accountPlan => {
        window.open(accountPlan.confirmationUrl!, "_blank");
        return dispatch({
          type: "EDITED_PLAN_FEES",
          payload: {
            accountPlan,
          },
        });
      }
    );

export const cancelPendingPlan =
  (
    addToast: (content: string, error?: boolean | undefined) => void
  ): SmartrrThunkAction<"CANCELING_PENDING_PLAN" | "ERROR_CANCELING_PENDING_PLAN" | "CANCELED_PENDING_PLAN"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/super/cancel-pending-plan"),
      () =>
        dispatch({
          type: "CANCELING_PENDING_PLAN",
          payload: undefined,
        }),
      failure => {
        addToast(`Error cancelling pending plan:${failure.message}`, true);
        return dispatch({
          type: "ERROR_CANCELING_PENDING_PLAN",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      accountPlan => {
        addToast("Cancelled pending plan, refresh to see changes");
        return dispatch({
          type: "CANCELED_PENDING_PLAN",
          payload: {
            accountPlan,
          },
        });
      }
    );
