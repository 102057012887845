import React from "react";

export const CsvDocumentIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.82965 0C4.72815 0 3.0249 1.704 3.0249 3.8055V44.1862C3.0249 46.2885 4.72815 47.9917 6.82965 47.9917H41.1602C43.2609 47.9917 44.9657 46.2885 44.9657 44.1862V15.2325L30.7884 0H6.82965V0Z"
        fill="#8C9196"
      />
      <path
        d="M18.2297 32.3978C18.3242 32.5058 18.3782 32.6408 18.3782 32.7893C18.3782 33.0728 18.1487 33.3293 17.8509 33.3293C17.7159 33.3293 17.5674 33.2753 17.4729 33.1538C16.9464 32.5193 16.0547 32.1135 15.2177 32.1135C13.2459 32.1135 11.7474 33.6263 11.7474 35.7735C11.7474 37.9073 13.2467 39.4328 15.2177 39.4328C16.0682 39.4328 16.9052 39.0683 17.4729 38.406C17.5667 38.298 17.7159 38.2313 17.8509 38.2313C18.1622 38.2313 18.3782 38.5005 18.3782 38.7848C18.3782 38.9198 18.3242 39.0548 18.2297 39.1628C17.5269 39.8918 16.5684 40.4325 15.2184 40.4325C12.6257 40.4325 10.5864 38.5418 10.5864 35.7735C10.5864 33.0053 12.6257 31.1145 15.2184 31.1145C16.5684 31.1153 17.5404 31.6553 18.2297 32.3978ZM23.3312 40.4333C21.9947 40.4333 20.9409 39.9878 20.1714 39.312C20.0499 39.204 19.9959 39.0555 19.9959 38.907C19.9959 38.6363 20.1984 38.3393 20.5224 38.3393C20.6304 38.3393 20.7519 38.3663 20.8464 38.4473C21.4674 39.0015 22.3314 39.4328 23.3717 39.4328C24.9789 39.4328 25.4919 38.5688 25.4919 37.8795C25.4919 35.5568 20.1579 36.84 20.1579 33.6255C20.1579 32.1405 21.4809 31.1273 23.2502 31.1273C24.4112 31.1273 25.4109 31.4775 26.1399 32.0858C26.2614 32.1938 26.3289 32.3423 26.3289 32.4908C26.3289 32.7608 26.0994 33.0308 25.8017 33.0308C25.6937 33.0308 25.5722 32.9903 25.4777 32.9093C24.8162 32.3693 23.9927 32.1263 23.1684 32.1263C22.0884 32.1263 21.3182 32.7068 21.3182 33.558C21.3182 35.5838 26.6522 34.422 26.6522 37.785C26.6529 39.096 25.7619 40.4333 23.3312 40.4333ZM36.0767 31.9928L32.9574 39.744C32.8089 40.1085 32.4437 40.3515 32.0664 40.3515H32.0394C31.6479 40.3515 31.2834 40.1085 31.1342 39.744L28.0277 31.9928C28.0007 31.9253 27.9872 31.8578 27.9872 31.7768C27.9872 31.5068 28.2294 31.182 28.5947 31.182C28.8242 31.182 29.0402 31.317 29.1347 31.5465L32.0514 39.0405L34.9682 31.5465C35.0492 31.3305 35.2652 31.182 35.5082 31.182C35.8592 31.182 36.1157 31.4655 36.1157 31.7768C36.1164 31.8443 36.1029 31.9253 36.0767 31.9928Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0007 15.2676V16.0176H35.4007C35.4007 16.0176 30.6667 15.0726 30.8047 10.9873C30.8047 10.9873 30.9607 15.2676 35.307 15.2676H45.0007Z"
        fill="#5C5F62"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8232 0.00439453V10.9251C30.8232 12.1671 31.6512 15.2684 35.4012 15.2684H45.0012L30.8232 0.00439453Z"
        fill="white"
      />
    </svg>
  );
};
