import { Button, DropZone, Icon, LegacyCard, ProgressBar, Text } from "@shopify/polaris";
import { UploadMajor } from "@shopify/polaris-icons";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { CsvDocumentIcon } from "@vendor-app/app/_sharedComponents/Icons";

interface IUploadFile {
  isRunScript: boolean;
  setIsRunScript: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropZoneWrapper = styled.div`
  .Polaris-DropZone {
    height: 250px;
  }
`;

const DropZoneContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  .Polaris-Icon {
    height: min-content;
    margin: 0 0 17px 0;
    width: 40px;
  }
  .Polaris-Button {
    margin-bottom: 17px;
  }
`;

const FileNameWrapper = styled.div`
  align-items: center;
  display: flex;
  .Polaris-Icon {
    height: min-content;
    margin-right: 18px;
    width: min-content;
  }
`;

const FileWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin: 20px;
`;

const CardWrapper = styled.div`
  border: 1px solid #c9cccf;
  border-radius: 8px;
  height: 210px;
`;

const ProgressBarWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  span:first-child {
    color: #347c84;
    font-size: 20px;
    line-height: 28px;
  }
  .Polaris-ProgressBar {
    margin: 8px 0;
  }
`;

const ProgressWrapper = styled.div`
  margin-bottom: 8px 0;
  width: 406px;
`;

const ProgressBarCompleteWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  span:first-child {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }
`;

export function UploadFile({ isRunScript, setIsRunScript }: IUploadFile): JSX.Element {
  const [files, setFiles] = useState<any>([]);
  const progress = 49;
  const isProgressComplete = false;

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles) => {
    setFiles((files: any) => [...files, ...acceptedFiles]);
  }, []);

  const uploadedFiles = files.length > 0 && (
    <React.Fragment>
      {files.map((file: any, index: number) => (
        <FileWrapper key={index}>
          <FileNameWrapper>
            <Icon source={CsvDocumentIcon} color="base" />
            <Text variant="bodyMd" as="span" color="subdued">
              {file.name}
            </Text>
          </FileNameWrapper>
        </FileWrapper>
      ))}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {isRunScript ? (
        <LegacyCard.Section>
          <CardWrapper>
            {files.map((file: any, index: number) => (
              <React.Fragment key={index}>
                <FileWrapper>
                  <FileNameWrapper>
                    <Icon source={CsvDocumentIcon} color="base" />
                    <Text variant="bodyMd" as="span" color="subdued">
                      {file.name}
                    </Text>
                  </FileNameWrapper>
                  {isProgressComplete ? (
                    <Button primary onClick={() => setIsRunScript(false)}>
                      New script
                    </Button>
                  ) : (
                    <Button plain destructive onClick={() => setIsRunScript(false)}>
                      Cancel script
                    </Button>
                  )}
                </FileWrapper>
                {isProgressComplete ? (
                  <ProgressBarCompleteWrapper>
                    <Text variant="bodyMd" as="span">
                      Script complete
                    </Text>
                    <Text variant="bodyMd" as="span">
                      (996) of (1,000) records successfully updated
                    </Text>
                  </ProgressBarCompleteWrapper>
                ) : (
                  <ProgressBarWrapper>
                    <Text variant="bodyMd" as="span">
                      {progress}% complete
                    </Text>
                    <ProgressWrapper>
                      <ProgressBar progress={progress} size="small" />
                    </ProgressWrapper>
                    <Text variant="bodyMd" as="span" color="subdued">
                      (499) of (1,000) records updated
                    </Text>
                  </ProgressBarWrapper>
                )}
              </React.Fragment>
            ))}
          </CardWrapper>
        </LegacyCard.Section>
      ) : (
        <DropZoneWrapper>
          <DropZone onDrop={handleDropZoneDrop} variableHeight accept=".csv">
            {uploadedFiles}
            {!files.length && (
              <DropZoneContent>
                <Icon source={UploadMajor} color="base" />
                <Button>Add file</Button>
                <Text variant="bodyMd" as="span" color="subdued">
                  or drop files to upload (.csv)
                </Text>
              </DropZoneContent>
            )}
          </DropZone>
        </DropZoneWrapper>
      )}
    </React.Fragment>
  );
}
