import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { create } from "zustand";

interface DmarcData {
  authenticatedEmailDomain: string;
  mailfrom: string;
  verified: boolean;
}

interface DmarcStore {
  isLoading: boolean;
  isSettingUp: boolean;
  isVerifying: boolean;
  dmarcData: DmarcData | null;
  actions: {
    loadDmarc(): Promise<void>;
    setUp(): Promise<{ result: "success" | "error" }>;
    verify(): Promise<{ result: "success" | "error"; verified: boolean }>;
  };
}

export const useDmarcStore = create<DmarcStore>((set, get) => ({
  isLoading: true,
  isSettingUp: false,
  isVerifying: false,
  dmarcData: null,
  actions: {
    async loadDmarc(): Promise<void> {
      const response = await typedFrontendVendorApi.getReq("/dmarc");
      if (response.type === "success") {
        set({
          isLoading: false,
          dmarcData: response.body,
        });
      } else {
        set({
          isLoading: false,
        });
      }
    },
    async setUp(): Promise<{ result: "success" | "error" }> {
      set({ isSettingUp: true });
      const response = await typedFrontendVendorApi.postReq("/dmarc/set-up");
      if (response.type === "success") {
        set({
          dmarcData: response.body,
        });
      }
      set({ isSettingUp: false });
      return { result: response.type };
    },
    async verify(): Promise<{ result: "success" | "error"; verified: boolean }> {
      set({ isVerifying: true });
      const response = await typedFrontendVendorApi.postReq("/dmarc/verify");
      if (response.type === "success") {
        set({
          dmarcData: response.body,
        });
      }
      set({ isVerifying: false });
      return { result: response.type, verified: get().dmarcData?.verified ?? false };
    },
  },
}));

export const DmarcStoreAccess = {
  useIsLoading() {
    return useDmarcStore(state => state.isLoading);
  },
  useIsSettingUp() {
    return useDmarcStore(state => state.isSettingUp);
  },
  useIsVerifying() {
    return useDmarcStore(state => state.isVerifying);
  },
  useDmarcData() {
    return useDmarcStore(state => state.dmarcData);
  },
  useActions() {
    return useDmarcStore(state => state.actions);
  },
};
