import { LegacyCard, Tabs, Page, PageActions } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { GraphQLView } from "./components/GraphQL";
import { RestView } from "./components/RestView";
import { ScriptsView } from "./components/ScriptsView";

enum TabsEnum {
  REST = "REST",
  GRAPHQL = "GRAPHQL",
  SCRIPTS = "SCRIPTS",
}

interface Props {
  organizationId?: string | null;
}

const PageWrapper = styled.div`
  .Polaris-Page {
    padding: 0;
  }
`;

export function DebugScripts({ organizationId }: Props): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isRunScript, setIsRunScript] = useState<boolean>(false);
  const [apiMap, setApiMap] = useState<{ [key: string]: string[] }>({});
  const [, setLoading] = useState(false);

  const tabs = [
    {
      content: "Rest API",
      id: TabsEnum.REST,
    },
    {
      content: "GraphQL API",
      id: TabsEnum.GRAPHQL,
    },
    {
      content: "Scripts",
      id: TabsEnum.SCRIPTS,
    },
  ];

  const fetchShopifyApiActions = useCallback(async () => {
    if (!organizationId) {
      return;
    }

    setLoading(true);
    const res = await typedFrontendVendorApi.getReq("/shopify-api");

    if (res.type === "success") {
      setApiMap(res.body);
    }

    setLoading(false);
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) {
      fetchShopifyApiActions();
    }
  }, [organizationId]);

  const selectedTabId = tabs[selectedTab].id;

  return (
    <PageWrapper>
      <Page>
        <LegacyCard>
          <Tabs selected={selectedTab} onSelect={setSelectedTab} tabs={tabs}>
            {selectedTabId === TabsEnum.REST ? (
              <RestView apiMap={apiMap} />
            ) : selectedTabId === TabsEnum.GRAPHQL ? (
              <GraphQLView />
            ) : (
              <ScriptsView isRunScript={isRunScript} setIsRunScript={setIsRunScript} />
            )}
          </Tabs>
        </LegacyCard>
        {selectedTabId === TabsEnum.SCRIPTS && (
          <PageActions
            primaryAction={{
              content: "Run script",
              disabled: isRunScript,
              onAction: () => setIsRunScript(true),
            }}
          />
        )}
      </Page>
    </PageWrapper>
  );
}
