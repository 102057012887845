import React from "react";

export const OnIcon = () => {
  return (
    <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="3" width="26" height="10" rx="5" fill="#94BCE6" />
      <circle cx="21" cy="8" r="8" fill="#2877CC" />
    </svg>
  );
};
