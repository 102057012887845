import { Button } from "@shopify/polaris";
import React, { useCallback, useState } from "react";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

export interface syncParams {
  contractId: string;
}

export function SyncOneContractButton({ contractId }: syncParams): React.JSX.Element {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const syncOnceContractHandler = useCallback(async () => {
    setLoading(true);
    const response = await typedFrontendVendorApi.postReq("/purchase-state/sync-one/:contractId", {
      params: {
        contractId: `${contractId}`,
      },
    });
    if (response.type === "success") {
      addToast("Contract synced successfully");
    } else {
      addToast("Error syncing contract", true);
    }
    setLoading(false);
  }, [contractId]);
  return (
    <Button onClick={syncOnceContractHandler} disabled={loading}>
      Force sync
    </Button>
  );
}
