import { LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

import { ToggleSetting } from "./ToggleSetting";

interface IFeatureFlagItem {
  flag: {
    id: string;
    label: string;
    description: string;
    selected: boolean;
  };
  handleToggle: (id: string, selected: boolean) => void;
  mutatedFlags: {
    id: string;
    selected: boolean;
  }[];
}

const FlagsInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .Polaris-LegacyStack {
    .Polaris-LegacyStack__Item {
      .Polaris-LegacyCard {
        background: #f6f6f7;
        border: 1px solid #d2d5d8;
        border-radius: 4px;
        font-family: monospace;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 3px;
        padding: 4px 6px;
      }
    }
  }
`;

export function FeatureFlagItem({ flag, handleToggle, mutatedFlags }: IFeatureFlagItem): JSX.Element {
  const { description, label, selected } = flag;
  const ele = mutatedFlags.find(ele => ele.id === flag.id);
  const isActive = ele ? ele.selected : selected;
  return (
    <FlagsInfoWrapper>
      <div>
        <LegacyStack spacing="tight">
          <LegacyCard>{label}</LegacyCard>
        </LegacyStack>
        <Text variant="bodyMd" as="span" color="subdued">
          {description}
        </Text>
      </div>
      <ToggleSetting active={isActive} onToggle={() => handleToggle(flag.id, !selected)} />
    </FlagsInfoWrapper>
  );
}
