export const planOptions = [
  { label: "Launch", value: "Launch" },
  { label: "Grow", value: "Grow" },
  { label: "Excel", value: "Excel" },
  { label: "Legacy Pro", value: 'Legacy Pro"' },
  { label: "Legacy Advanced", value: "Legacy Advanced" },
  { label: "Free Trial", value: "Free Trial" },
];

export const planFeatures = [
  {
    id: Date.now(),
    planName: "Launch",
    monthlyFee: "99",
    subscriberFee: "1",
    transactionFee: "",
    features: "Core Configuration, Core Subscriptions, Analytics",
  },
  {
    id: Date.now(),
    planName: "Grow",
    monthlyFee: "299",
    subscriberFee: "1",
    transactionFee: "",
    features:
      "Core Configuration, Core Subscriptions, Looker Analytics, Retention, Integrations, Prepaid Subscription Plans, Sequential Subscription Plans, Creator Lists, Instagram",
  },
  {
    id: Date.now(),
    planName: "Excel",
    monthlyFee: "499",
    subscriberFee: "1",
    transactionFee: "",
    features:
      "Core Configuration, Core Subscriptions, Looker Analytics, Retention, Integrations, Prepaid Selling Plans, Sequential Subscription Plans, Advanced Sequential Subscription Plans, Creator Lists, Instagram Loyalty, Bundles",
  },
  {
    id: Date.now(),
    planName: "Legacy Pro",
    monthlyFee: "49",
    subscriberFee: "1",
    transactionFee: "0.05",
    features: "Core Configuration, Core Subscriptions, Analytics",
  },
  {
    id: Date.now(),
    planName: "Legacy Advanced",
    monthlyFee: "199",
    subscriberFee: "1",
    transactionFee: "0.20",
    features:
      "Core Configuration, Core Subscriptions, Looker Analytics, Retention, Integrations, Prepaid Subscription Plans, Sequential Subscription Plans",
  },
];
