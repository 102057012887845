import styled from "styled-components";

export const Select = styled.select`
  border: 1px solid #ddd;
  min-width: 300px;
  padding: 8px 12px;
`;

export const ResponseHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
  .Polaris-Button {
    border: none;
    min-width: max-content;
    padding: 0px;
  }
`;

export const ResponseText = styled.pre`
  border: 1px solid #ddd;
  font-family: monospace;
  height: 400px;
  line-height: normal;
  overflow-y: auto;
  padding: 4px;
  position: relative;
  white-space: pre-wrap;
  width: 100%;

  > div {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
  }
`;
